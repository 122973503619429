var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_U0Ul8S5O3u38cILl_SQC"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/tenders";

import * as Sentry from "@sentry/nextjs";

const sentryDSN = process.env.SENTRY_DSN;

if (process.env.SENTRY_ENABLE) {
  Sentry.init({
    dsn: sentryDSN,
    tracesSampleRate: 1.0,
    environment: process.env.APP_ENV,
  });
}
